import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useProfile } from "../../../context/ProfileContext";
import { ToastContent, ToolbarAnalysis } from "../../../shared/components";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { IVFormErros, useVForm, VForm } from "../../../shared/forms";
import { checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { analysisService, IPlan } from "../../../shared/services/api/analysis/analysisService";
import { globalRemoveMaskReal2 } from "../../../shared/utils";
import { IFormData } from "./AnalysisRequest/interfaces";
import PropertyDataSection from "./AnalysisRequest/PropertyDataSection";
import RentValuesSection from "./AnalysisRequest/RentValuesSection";
import { formValidationSchema } from "./AnalysisRequest/schemas";
import TenantDataSection from "./AnalysisRequest/TenantDataSection";
import LoadingModal from "./ModalLoadSteps";
import ModalResultAnalysisAll from "./ModalResultAnalysisAll";
import LoadingDialog from "../../../shared/components/LoadingModalDialog/LoadingModalDialog";
import { set } from "lodash";

export const AnalysisRequestPresentation: React.FC = () => {
	const navigate = useNavigate();
	const { id = "novo" } = useParams<"id">();
	const { formRef, save } = useVForm();
	const [isLoading, setIsLoading] = useState(false);
	const [openModalResultAnalysis, setOpenModalResultAnalysis] = useState(false);
	const [plan, setPlan] = useState<IPlan[]>([]);
	const [nome, setNome] = useState("");
	const [rentAmount, setRentAmount] = useState(0);
	const [assuranceBaseCalc, setAssuranceBaseCalc] = useState<number>(0);
	const [chargesBaseCalc, setChargesBaseCalc] = useState<number>(0);
	const [status, setStatus] = useState("");
	const [propFinally, setProFinally] = useState("");
	const [idcreate, setIdcreate] = useState("");
	const { permissionLevel } = useProfile();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const toastHandler = useToast();
	const [rows, setRows] = useState<any>([]);
	const [modalLoad, setModalLoad] = useState(false);
	const [showButtonReanalysis, setShowButtonReanalysis] = useState(false);
	const [isSendSerasa, setIsSendSerasa] = useState(true);
	const [isContractRunner, setIsContractRunner] = useState(false);
	const [isTipping, setIsTipping] = useState(false);
	const [loadConclude, setLoadConclude] = useState(false);

	const handleShowButtonReanalysis = (value: boolean) => setShowButtonReanalysis(value);
	const handleIsSendSerasa = (value: boolean) => setIsSendSerasa(value);
	const handleIsContractRunner = (value: boolean) => setIsContractRunner(value);
	const handleIsTipping = (value: boolean) => setIsTipping(value);
	const handleModalToggle = (isOpen: boolean) => setOpenModalResultAnalysis(isOpen);

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const result = await analysisService.getById(id);
			if (result instanceof Error) {
				alert(result.message);
				navigate("/analises");
			} else {
				setNome(result.name);
				formRef.current?.setData(result);
				setRows(result);
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (id !== "novo") fetchData();
		else formRef.current?.setData(getDefaultFormData());
	}, [id]);

	const getDefaultFormData = () => ({
		clientId: "",
		typePerson: "physical",
		document: "",
		name: "",
		status: "pending",
		birthDate: "2000-05-01",
		motherName: "",
		maritalStatus: "",
		sex: "",
		email: "",
		phone: "",
		rentMonthsCount: "12",
		employmentRelationShip: "",
		isTipping: "",
		sendSerasa: "send_serasa",
		sendclick: "send",
		serasaResponse: permissionLevel === 1 ? "" : "consulta serasa ",
		propertyType: "Apartamento Padrao",
		propertyFinally: "residential",
		addressZipcode: "",
		addressStreet: "",
		addressNumber: "",
		addressComplement: "",
		addressCity: "",
		addressState: "MG",
		addressIBGE: "134001",
		addressDistrict: "",
		isContractRunner: "",
		contractBegin: "",
		contractEnd: "",
		rentAmount: "0",
		condominiumAmount: "0",
		electricEnergyAmount: "0",
		waterAmount: "0",
		gasAmount: "0",
		iptuAmount: "0",
		rentalCost: "only_rent",
		createdFrom: "web_app",
	});

	const handleSave = async (dados: IFormData) => {
		setIsLoading(true);
		setLoadConclude(true);
		const sanitizedData = sanitizeData(dados);

		if (sanitizedData.phone.length < 3 || sanitizedData.phone[2] !== "9") {
			showToast("Erro número de celular inválido!! Verifique!", "error");
			setIsLoading(false);
			setLoadConclude(false);
			return;
		}

		if (await verifyCellPhoneAndCPF(sanitizedData.document, sanitizedData.phone)) {
			setIsLoading(false);
			setLoadConclude(false);
			return;
		}

		try {
			//console.log("Iniciando a validação dos dados:", sanitizedData);
			const validatedData = await formValidationSchema.validate(sanitizedData, { abortEarly: false });
			//console.log("Validação bem-sucedida, dados validados:", validatedData);
			const success = await saveAnalysis(validatedData);
			if (!success) {
				showToast("Erro ao salvar a análise. Tente novamente.", "error");
			}
		} catch (error) {
			if (error instanceof yup.ValidationError) {
				//	console.error("Erros de validação dos dados:");
				error.inner.forEach((err) => {
					console.log(`Campo: ${err.path}, Erro: ${err.message}`);
				});
				handleValidationErrors(error); // Exibe os erros no formulário
			} else {
				console.error("Erro inesperado:", error);
				showToast("Erro inesperado. Tente novamente.", "error");
			}
		} finally {
			setIsLoading(false);
			setLoadConclude(false);
		}
	};

	const sanitizeData = (dados: IFormData) => {
		const sanitizeNumber = (value: string) => globalRemoveMaskReal2(value);
		return {
			...dados,
			phone: dados.phone.replace(/\D/g, "").slice(0, 11),
			rentAmount: sanitizeNumber(dados.rentAmount),
			condominiumAmount: sanitizeNumber(dados.condominiumAmount),
			electricEnergyAmount: sanitizeNumber(dados.electricEnergyAmount),
			waterAmount: sanitizeNumber(dados.waterAmount),
			gasAmount: sanitizeNumber(dados.gasAmount),
			iptuAmount: sanitizeNumber(dados.iptuAmount),
			sendSerasa: permissionLevel === 1 && !isSendSerasa ? "not_send_serasa" : "send_serasa",
			isTipping: isTipping ? "1" : "0",
			serasaResponse: permissionLevel === 1 && !isSendSerasa ? dados.serasaResponse : "obrigatório envio serasa",
			isContractRunner: isContractRunner ? "1" : "0",
			contractBegin: isContractRunner ? dados.contractBegin : null,
			contractEnd: isContractRunner ? dados.contractEnd : null,
			document: dados.document.replace(/\D/g, "").trim(),
			lmi: "0",
			lmiAmount: "0",
			rentalCost: "only_rent",
			relation: "{}",
			planlist: "{}",
			createdFrom: "web_app",
			status: "pending",
			paymentMethod: "billet",
			serasaAnalysisStatus: "approved",
			sendClick: false ? "not_send" : "send",
		};
	};

	const saveAnalysis = async (validatedData: IFormData) => {
		try {
			const result = await analysisService.create(validatedData, id);
			if (result instanceof Error) {
				showToast(result.message, "error");
				return false;
			}

			setProFinally(result.propertyFinally);
			setRentAmount(parseFloat(result.rentAmount));
			setChargesBaseCalc(parseFloat(result.rentAmount) + parseFloat(result.condominiumAmount) + parseFloat(result.iptuAmount));
			setAssuranceBaseCalc(parseFloat(result.rentAmount));
			setStatus(result.status || "pending");
			setIdcreate(result.id);
			setRows(result); // Atualiza os dados da análise com os dados retornados do servidor

			const plans = JSON.parse(result.planlist || "[]").filter((p: IPlan) => !p.flag_analysis || p.flag_analysis === "1");
			setPlan(plans);

			if (result.status === "pending") {
				setModalLoad(true);
			} else {
				setOpenModalResultAnalysis(true);
			}

			return true;
		} catch (error) {
			console.error("Erro ao salvar análise:", error);
			showToast("Erro ao salvar análise", "error");
			return false;
		}
	};

	const handleValidationErrors = (errors: yup.ValidationError) => {
		const validationErrors: IVFormErros = {};
		errors.inner.forEach((error) => {
			if (!error.path) return;
			validationErrors[error.path] = error.message;
		});
		formRef.current?.setErrors(validationErrors);
		showToast("Verifique se todos os campos estão preenchidos", "error");
	};

	const verifyCellPhoneAndCPF = async (document: string, cellphone: string) => {
		const result = await analysisService.verifyCellPhoneAndCPF(document, cellphone);
		if (result.status=='error') {
			showToast(`Erro: ${result.message}`, "error");
			return true;
		}
		return false;
	};

	const showToast = (contentMessage: string, type: "error" | "success" | "info") => {
		toastHandler.present({
			type,
			position: "top-right",
			messageOrContent: <ToastContent title={"Alerta!"} content={contentMessage} />,
		});
	};

	return (
		<LayoutBaseDePagina
			titulo="Análises"
			subtitulo={id === "novo" ? "Nova análise de crédito" : `Alteração de análise - Reanálise [ ${nome} ]`}
			barraDeFerramentas={
				<ToolbarAnalysis
					clickAnalysis={save}
					showButtonAnalysis={
						!isLoading && checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Conclude) && showButtonReanalysis
					}
					clickCancel={() => handleModalToggle(true)}
					clickBack={() => navigate("/analises")}
					showButtonCancel={false}
					showButtonProposal={false}
					showButtonEmailresend={false}
					showButtonReAnalysis={false}
				/>
			}
		>
			{loadConclude && <LoadingDialog isLoading={loadConclude} loadingText={`Realizando análise...`} />}

			{openModalResultAnalysis && (
				<ModalResultAnalysisAll
					isCloseModal={() => handleModalToggle(false)}
					isOpenModal={openModalResultAnalysis}
					smDown={smDown}
					assuranceBaseCalc={assuranceBaseCalc}
					chargesBaseCalc={chargesBaseCalc}
					status={status}
					Rows={rows}
					plan={plan}
					idcreate={idcreate}
					propFinally={propFinally}
				/>
			)}

			{/* {modalLoad && status === "pending" && ( */}
			{modalLoad  && (
				<LoadingModal
					isOpenModal={modalLoad}
					isClose={(newStatus) => {
						setModalLoad(false);
						//console.log("Novo status__: ", newStatus);
						setStatus(newStatus); // Armazena o status no estado do componente pai
						const updatedRows = { ...rows, status: newStatus };
						setRows(updatedRows);
						handleModalToggle(true); // Exemplo de função para abrir o modal com base no novo status
					}}
					isRepeat={5}
					Row={rows}
				/>
			)}

			<Box sx={{ width: "100%", maxWidth: "1300px" }}>
				<VForm id="formIdSave" ref={formRef} onSubmit={handleSave} onPointerEnterCapture={""} onPointerLeaveCapture={""} placeholder={""}>
					<TenantDataSection
						formRef={formRef}
						isLoading={isLoading}
						permissionLevel={1}
						Rows={rows}
						id={id}
						isSendSerasa={handleIsSendSerasa}
						isTypping={handleIsTipping}
					/>
					<PropertyDataSection formRef={formRef} isLoading={isLoading} />
					<RentValuesSection
						formRef={formRef}
						isLoading={isLoading}
						Rows={rows}
						showButtonReanalysis={handleShowButtonReanalysis}
						isContractRunner={handleIsContractRunner}
					/>
				</VForm>
			</Box>
		</LayoutBaseDePagina>
	);
};
